/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../redux-magic/store";
import { ObjectId } from "mongodb";

/* Component Imports */

import { IconButton, Skeleton, Tooltip, useTheme, Paper, Box, Typography } from "@mui/material";
import { useRouter } from "next/router";
import DeleteListingPropertyModal from "../../../modals/PropertyModal/DeleteListingProperty";

/* Icon Imports */

import { Bolt, BookmarkOutlined, BookmarkBorderOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "#E6DACF",
	padding: "0.25rem",
	width: "2rem",
	height: "2rem",
	borderRadius: "12px",
	"&:hover": {
		background: "#E6DACF",
	},
}));
const BoltContainer = styled("div")(({ theme }) => ({
	background: "white",
	"&:hover": {
		background: "white",
	},
}));

/*

 & Setting a constant image for when the image is not available or something goes wrong

*/

const fallbackImage: string = process.env.CDN_URL + "images/uploads/property.jpg";

const GridImage = ({
	_id,
	images,
	dispatch,
	profile_context,
	session,
	loading,
	saved_content,
	boosting,
}: {
	_id: ObjectId | string;
	images: string;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	session: any;
	loading: boolean;
	saved_content: Array<any>;
	boosting?: any;
}) => {
	const theme = useTheme();

	/*

	& Check if page is loaded. i loaded set to true. if page loads we use regular image. if not we use lqip image.
	
	*/
	const [isPageLoaded, SetIsPageLoaded] = React.useState<boolean>(false);

	React.useEffect(() => {
		SetIsPageLoaded(true);
	}, [loading]);

	/* Pathname */

	const router = useRouter();

	const pathname = React.useRef(router).current;

	const [isHovered, setHovered] = React.useState(false);

	/*

	& Next, let's declare  local state to handle bookmark.

	*/

	const [bookmark, setBookmark] = React.useState(
		saved_content?.find((item) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id ===
						(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
				: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
		}) !== undefined,
	);

	React.useEffect(() => {
		setBookmark(
			saved_content?.find((item) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id ===
							(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
					: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
			}) !== undefined,
		);
	}, [profile_context, saved_content]);

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: _id,
					entity_type: "service",
					add_remove: !bookmark ? "add" : "remove",
					url: pathname.asPath ? pathname.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="100%"
					height="100%"
					style={{ borderRadius: "16px", margin: "0rem" }}
				/>
			</React.Fragment>
		);
	}

	let BoostingCheck = boosting?.active ? true : false;

	/* Boosting end date */

	const date = new Date(boosting?.ends_at);

	/* Date , Month and year format*/

	const formattedDate = `
  			${date.getDate()} 
  			${date.toLocaleString("en-US", {
					month: "short",
				})} 
  			${date.getFullYear().toString().slice(2)}`;

	const BoostPaper = (
		<Paper
			sx={{
				position: "absolute",
				top: 13,
				left: 13,
				zIndex: 4,
				display: "flex",
				justifyContent: "center",
				alignItems: "space-between",
				height: "2.25rem",
				padding: "0.43rem",
				flexDirection: "column",
				overflow: "hidden",
				borderRadius: "16px",
				boxShadow: "none",
				backgroundColor: "white",
			}}
		>
			<BoltContainer
				// onClick={(event: any) => {
				//   event.preventDefault();
				//   session === null ? window.open("/auth/login") : submit();
				// }}
				sx={{
					display: "flex",
					width: !isHovered ? "1.5rem" : "11rem",
					height: "2rem",
					gap: "0.5rem",
					overflow: "hidden",
					borderRadius: "16px",
					transition: "width 0.25s ease-in-out",
				}}
			>
				<Bolt
					sx={{ color: theme.palette.primary.main }}
					fontSize="medium"
				/>
				<Typography
					sx={{
						color: "black",
					}}
				>
					{session === null ? "Boosted Property" : "Ends on " + formattedDate}
				</Typography>
			</BoltContainer>
		</Paper>
	);

	return (
		<React.Fragment>
			<Container
				sx={{
					borderTop: BoostingCheck ? "3px solid #fdb375" : "none",
					borderRight: BoostingCheck ? "3px solid #fdb375" : "none",
					borderLeft: BoostingCheck ? "3px solid #fdb375" : "none",
				}}
			>
				{/* Bookmark Icon */}

				{session !== null ? (
					<BookmarkIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? window.open("/auth/login") : submit();
						}}
						sx={{
							position: "absolute",
							top: 10,
							right: 10,
							zIndex: 4,
						}}
					>
						<Tooltip title="Favourite">
							{bookmark == false ? (
								<BookmarkBorderOutlined
									sx={{ color: "#623816" }}
									fontSize="small"
								/>
							) : (
								<BookmarkOutlined
									sx={{ color: "#623816" }}
									fontSize="small"
								/>
							)}
						</Tooltip>
					</BookmarkIconButton>
				) : null}

				{/* Boost Icon */}

				{boosting?.active === true ? (
					<div
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={{
							position: "absolute",

							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
					>
						<Box sx={{ width: "100%" }}>
							<div>{BoostPaper}</div>
						</Box>
					</div>
				) : null}

				<Image
					sx={{
						objectFit: images[0].includes("images/uploads/placeholder_1080p_old.svg") ? "fill" : "cover",
					}}
					src={images ? (images.length > 0 ? (isPageLoaded ? images[0] : images[0]) : fallbackImage) : ""}
					alt="Service Image"
					referrerPolicy="no-referrer"
				/>
			</Container>
		</React.Fragment>
	);
};

export default GridImage;
