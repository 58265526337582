/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { UserInterface } from "../../../../../redux-magic/sub-interfaces/user-interface";
import { BusinessProfileInterface } from "../../../../../redux-magic/sub-interfaces/business-profile-interface";
import { StoreLeadsThunk } from "../../../../../redux-magic/store";
import { ObjectId } from "bson";

/* Component Imports */

import { Typography, Skeleton, Snackbar, useTheme } from "@mui/material";
import BeegruButton from "../../../../common-components/buttons/BeegruButton";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
			sx={{
				zindex: 8,
			}}
		/>
	);
});

/* Icon Imports */

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

/* Styled Components */

const Snack = styled(Snackbar)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "auto",
	margin: "2.5rem 0rem 0rem 0rem",
	zindex: 8,
	".&MuiSnackbar-root": {
		top: "0px",
		zindex: 8,
	},
}));

// const Heading2 = styled(Typography)(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "column",
// 	justifyContent: "flex-start",
// 	alignItems: "flex-start",
// 	width: "100%",
// 	margin: "0rem 0.2rem 0rem 0rem",
// }));

// const DeveloperLink = styled(Link)(({ theme }) => ({
// 	textDecoration: "none",
// 	color: theme.palette.mode == "dark" ? "#fff" : "#000",
// 	"&:hover": {
// 		textDecoration: "none",
// 	},
// }));

const PropertyCardConfigAndContact = ({
	loading,
	developer,
	config,
	card_title,
	dispatch,
	session,
	slug,
	title,
	created_by,
	show_contact_details,
}: {
	loading: boolean;
	developer: string;
	config: string | null | undefined;
	card_title: string | undefined;
	dispatch: Function;
	session: any;
	slug: string;
	title: string;
	created_by: any;
	show_contact_details: boolean;
}) => {
	const theme = useTheme();

	const [snackopen, setSnackOpen] = React.useState<boolean>(false);

	const phone = created_by?.is_business_profile
		? created_by.business_profile_id
			? created_by.business_profile_id.phone
				? created_by.business_profile_id.phone.country_code + " " + created_by.business_profile_id.phone.phone_number
				: ""
			: ""
		: created_by?.user_id
			? created_by.user_id.country_code
				? created_by.user_id.country_code + " " + created_by.user_id.phone
				: ""
			: "";

	const submit = async (call_now: boolean) => {
		call_now ? null : setSnackOpen(true);
		await dispatch(
			StoreLeadsThunk({
				name: session
					? session.user
						? session.user.dbUser
							? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
							: ""
						: ""
					: "",
				phone: {
					country_code: session
						? session.user
							? session.user.dbUser
								? session.user.dbUser.country_code
								: "+91"
							: "+91"
						: "+91",
					phone_number: session ? (session.user ? (session.user.dbUser ? session.user.dbUser.phone : "") : "") : "",
				},
				agreed_to_tc: true,
				source: "/properties/" + slug,
				listing_slug: slug,
				listing_type: "Property",
				is_guest: session ? false : true,
				listing_title: title,
				listing_owner: {
					is_business_profile: created_by?.is_business_profile,
					user_id: created_by
						? created_by.is_business_profile
							? (created_by?.user_id as string | ObjectId)
							: created_by?.user_id !== ""
								? (created_by?.user_id as UserInterface)?._id
								: undefined
						: undefined,
					business_profile_id: created_by
						? created_by?.is_business_profile
							? (created_by?.business_profile_id as BusinessProfileInterface)?._id
							: (created_by?.business_profile_id as string | ObjectId)
						: undefined,
				},
				user_id: session ? (session.user ? (session.user.dbUser ? session.user.dbUser._id : "") : "") : "",
			}),
		);
		setSnackOpen(false);
	};

	const handleClick = () => {
		window.location.href = `tel:${phone}`;
	};

	const handleCloseSnack = () => {
		setSnackOpen(false);
	};

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	}

	return (
		<React.Fragment>
			<Snack
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={snackopen}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", zIndex: 8 }}
				>
					We&apos;ll get back to you soon!
				</Alert>
			</Snack>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "flex-end",
					width: "100%",
					margin: "0rem 0rem 0rem 0rem",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						alignItems: "flex-start",
						height: "100%",
						width: "46%",
					}}
				>
					<Typography variant="body1">
						{/* <DeveloperLink
							href={`/developers/${created_by.business_profile_id.slug}`}
							rel="noopener"
							target={"_blank"}
							referrerPolicy="no-referrer"
							title={developer ? developer : "Unknown Developer"}
							sx={{
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								maxWidth: "100%",
								"&:hover": {
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									color: "#FC8019",
								},
							}}
						>
							
						</DeveloperLink> */}
						{config}
					</Typography>
					<Typography
						variant="body2"
						color={theme.palette.text.secondary}
					>
						Configuration
					</Typography>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "52%",
						padding: "0rem 0rem 0rem 0.75rem",
					}}
				>
					{!show_contact_details ? (
						<BeegruButton
							variant="outlined"
							color="primary"
							// size="small"
							sx={{
								fontSize: "0.875rem",
								lineHeight: "1rem",
								letterSpacing: "0.025rem",
								fontWeight: 400,
								width: "100%",
								height: "100%",
								padding: "0.5rem 0.5rem 0.5rem 0.5rem",
							}}
							onClick={(event: any) => {
								event.preventDefault();
								session === null ? window.open("/auth/login") : submit(false);
							}}
						>
							Get a callback
						</BeegruButton>
					) : (
						<BeegruButton
							variant="outlined"
							color="primary"
							// size="small"
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								fontSize: "0.875rem",
								lineHeight: "1rem",
								letterSpacing: "0.025rem",
								fontWeight: 400,
								height: "100%",
								width: "100%",
								padding: "0.438rem 0.5rem 0.438rem 0.5rem",
								// gap: "0.5rem",
							}}
							startIcon={<LocalPhoneOutlinedIcon />}
							onClick={handleClick}
						>
							Call now
						</BeegruButton>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PropertyCardConfigAndContact;
