/* Common Imports */

import React from "react";
import OFBCardGridView from "./OFBCardGridView/OFBCardGridView";

/* Component Imports */

import OFBLoadingCard from "../skeletonLoadingCard/OFBLoadingCard";

/* Styled Components */

const OFBCardFetcher = ({
	id,
	dispatch,
	session,
	profile_context,
	source,
}: {
	id: any;
	dispatch: any;
	session: any;
	profile_context: any;
	source?: string;
}) => {
	const [ofbData, setOfbData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (id) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${id}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setOfbData(data.property_data);
					setLoading(false);
				});
		}
	}, [id]);

	if (loading) {
		return <OFBLoadingCard />;
	}

	return (
		<React.Fragment>
			<OFBCardGridView
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={ofbData._id}
				slug={ofbData.slug}
				property_category={ofbData.property_category}
				property_type={ofbData.property_type}
				title={ofbData.title}
				images={ofbData.images}
				location={ofbData.location ? ofbData.location.title : ""}
				saved_content={ofbData.saved_content}
				boosting={ofbData.boost_information}
				created_by={ofbData.created_by}
				show_contact_details={ofbData.show_contact_details}
				source={source}
			/>
		</React.Fragment>
	);
};

export default OFBCardFetcher;
