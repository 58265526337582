/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton } from "@mui/material";

/* Styled Components */

const OFBCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "23.125rem",
	margin: "0.1rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "23.125rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.75rem 0.9rem 0rem 0.9rem",
	width: "100%",
	height: "100%",
	zIndex: 5,
}));

const TitlePriceSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 1rem 0rem",
}));

const AreaStatusContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 0.5rem 0rem",
	gap: "1rem",
}));

const AreaStatusSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const BuilderDetailSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	margin: "0rem 0rem 0.5rem 0rem",
}));

const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const OFBLoadingCard = ({ source }: { source?: string }) => {
	return (
		<React.Fragment>
			{data.map((data: any, index: number) => (
				<OFBCardContainer
					key={index}
					source={source ? source : ""}
				>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="100%"
						style={{ borderRadius: "16px 16px 0px 0px" }}
					/>

					<OFBCardInfoContainer>
						<TitlePriceSkeleton>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="80%"
								height="1.5rem"
								style={{ borderRadius: "0.25rem" }}
							/>
						</TitlePriceSkeleton>

						<AreaStatusContainer>
							<AreaStatusSkeleton>
								<Skeleton
									animation="wave"
									variant="text"
									width="100%"
									height="auto"
								/>
								<Skeleton
									animation="wave"
									variant="text"
									width="30%"
									height="auto"
								/>
							</AreaStatusSkeleton>
							<Skeleton
								animation="wave"
								variant="rectangular"
								height="2.5rem"
								width="0.5rem"
								sx={{ borderRadius: "0.25rem" }}
							/>
							<AreaStatusSkeleton>
								<Skeleton
									animation="wave"
									variant="text"
									width="100%"
									height="auto"
								/>
								<Skeleton
									animation="wave"
									variant="text"
									width="30%"
									height="auto"
								/>
							</AreaStatusSkeleton>
						</AreaStatusContainer>

						<Skeleton
							animation="wave"
							variant="text"
							width="100%"
							height="3.75rem"
							style={{
								borderRadius: "12px",
							}}
						/>
					</OFBCardInfoContainer>
				</OFBCardContainer>
			))}
		</React.Fragment>
	);
};

export default OFBLoadingCard;
