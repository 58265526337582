/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0.35rem 0rem 0.45rem 0.25rem",
}));

const GridCardTitle = ({ loading, title }: { loading: boolean; title: string }) => {
	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
			/>
		);
	}

	return (
		<React.Fragment>
			<Heading variant="body1">{title ? (title.length < 28 ? title : title?.substring(0, 28) + "...") : ""}</Heading>
		</React.Fragment>
	);
};

export default GridCardTitle;
